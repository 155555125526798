import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Separator from '@vkontakte/vkui/dist/components/Separator/Separator';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Text  from '@vkontakte/vkui/dist/components/Typography/Text/Text';
import Title  from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Caption  from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import File from '@vkontakte/vkui/dist/components/File/File';
import { Snackbar, Avatar,  } from '@vkontakte/vkui';
import Icon16Cancel from "@vkontakte/icons/dist/16/cancel";


import Icon24User from '@vkontakte/icons/dist/24/user';
import Icon24Camera from "@vkontakte/icons/dist/24/camera";

import "./input_handler.js"
import "./main.css"
// ({id, fetchedUser, swap_face, snackbar, setImg1, setImg2, uploadImage})
const Home = props => (
	<Panel id={props.id}>
				<link rel="stylesheet" href="main.css"/>

		<PanelHeader>Замена лиц</PanelHeader>
		<Group title="text">
			<Div  style={{ paddingBottom: 0 }}>
							<Title level="1" weight="heavy" style={{ marginBottom: 10 }}>Привет! &#128578;</Title>
							<Text weight="regular">В нашем боте ты можешь заменить лицо на фото! Это абсолютно <b>бесплатно</b>!</Text>
			</Div>
		</Group>
		<Group title="input" >
			<FormLayout>
				<File id="file_face" onChange={props.uploadImage} top="1. Загрузите фото с которого брать лицо" before={<Icon24Camera />} controlSize="xl" accept="image/x-png,image
				,image/jpeg">
				Открыть галерею
				</File>
				<File id="file_body" onChange={props.uploadImage} top="2. Загрузите фото на которое накладывать лицо" before={<Icon24Camera />} controlSize="xl" accept="image/x-png,image
				,image/jpeg">
				Открыть галерею
				</File>
				
		</FormLayout>

		<Group>
		<Separator></Separator>
			<Div>
			{/* onClick={() => swap_face()} */}
			{/* {fetchedUser && <p>fetchedUser.id</p>} */}
			<Button id="swap_face_button" size="xl" mode="secondary" onClick={e => props.swap_face()} before={<Icon24User />} >Заменить лицо</Button>
			{/* {fetchedUser && <Button size="xl" mode="secondary" before={<Icon24User />} onClick={() => swap_face(fetchedUser.id)}>Заменить лицо</Button>} */}
				{/* <Text>Фото 1: не загружено &#128683;</Text> */}
				
			</Div>
			<Div style={{display: 'flex', paddingTop: 0}}>	
				<Button onClick={e => props.changeShowImage(e, "file_face_button")} size="l" disabled={props.styleShowButton.file_face_button} id="file_face_button" stretched mode="commerce" style={{ marginRight: 8 }} >Фото с лицом</Button>
				<Button onClick={e => props.changeShowImage(e, "file_body_button")} size="l" disabled={props.styleShowButton.file_body_button} id="file_body_button" stretched mode="commerce" >Фото с телом</Button>
			</Div>
				{/* <span>{props.ImgCurrent.imagePreviewUrl}</span> */}
				<img id="upload_image" src={props.ImgCurrent.imagePreviewUrl}></img>
				{/* <div style={{backgroundImage: props.ImgCurrent.imagePreviewUrl}}>ввв</div> */}
				{/* <input ref={element => setImg1(element)} value="sdsd" type="text"/> */}
				<div style={{display: 'flex', paddingTop: 0}}>
					<input id="img_box1" style={{visibility: 'hidden' }} ref={props.setImg1} defaultValue="dsdsdsds"/>
					<input id="img_box2" style={{visibility: 'hidden' }} ref={props.setImg2} defaultValue="fdfdfdfdf"/>
				</div>
				
		</Group>
		<script src="input_handler.js"></script>
		</Group>
		{/* <Snackbar 
				layout="vertical"
				before={<Avatar size={24} style={{backgroundColor: '#f56767'}}><Icon16Cancel fill="#fff" width={14} height={14} /></Avatar>}
>
					Загрузите обе картинки
			</Snackbar> */}
		{props.snackbar}
	</Panel>
	
);

Home.propTypes = {
	id: PropTypes.string.isRequired,
	// go: PropTypes.func.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.number
	}),
};

export default Home;
