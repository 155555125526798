import React, { useState, useEffect, useRef } from 'react';
import { Snackbar, Avatar, Spinner } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import Icon16Cancel from "@vkontakte/icons/dist/16/cancel";
import '@vkontakte/vkui/dist/vkui.css';

import default_image from './img/white.jpg';
import Home from './panels/Home';
import Result from './panels/Result';

// import FormData from 'form-data'
import axios from "axios";
import { cacheAdapterEnhancer } from 'axios-extensions'

let url_image = 'https://faceswap.dnatree.ru/result_data/';
let url_api = 'https://swap-face.dnatree.ru/';
let photo_dir = "result_data/";
let last_image = ''
let file_face = null;
let file_body = null;
let styleShowButton = {
	file_face_button: true,
	file_body_button: true
}

const api = axios.create({
	baseURL: url_api,
	headers: {'Cache-Control': 'no-cache, no-store'},
	adapter: cacheAdapterEnhancer(axios.defaults.adapter, true),
	responseType: "json"
})
// <ScreenSpinner size='large' />
const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const [popout, setPopout] = useState(null);
	const [snackbar, setSnackbar] = useState(null);
	const [fetchedUser, setUser] = useState(null);
	const [ImgCurrent, setImgCurrent] = useState({
		file: null,
		imagePreviewUrl: default_image
	});
	console.log(fetchedUser)
	const [imgResult, setImgResult] = useState(null);
	const [resultImage, setResultImage] = useState(null);
	const [resultSpinner, setResultSpinner] = useState(null);
	// last_image = fetchedUser.id + '.jpg'
	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
			setPopout(null);
		}
		fetchData();
	}, []);

	const changeShowImage = (e, id) => {
		if(id === 'file_face_button'){
			setImgCurrent(file_face)
		}
		else{
			setImgCurrent(file_body)
		}
	}

	const uploadImage = e =>{
		let reader = new FileReader();
		let file = e.target.files[0];
		let element_id = e.target.id;

		reader.onloadend = () => {
		if(element_id === 'file_face'){
			styleShowButton.file_face_button = false;
			const fd = new FormData();
			fd.append('file', file);
			fd.append('file_name', `${fetchedUser.id}_face.jpg`);
			axios.post(url_api + 'upload.php', fd)
			// .then(res => console.log(res));
			file_face = {
				file: file,
				imagePreviewUrl: reader.result
			};
			setImgCurrent(file_face);
		}
		else {
			styleShowButton.file_body_button = false;
			const fd = new FormData();
			fd.append('file', file);
			fd.append('file_name', `${fetchedUser.id}_body.jpg`);
			axios.post(url_api + 'upload.php', fd)
			// .then(res => console.log(res));
			file_body = {
				file: file,
				imagePreviewUrl: reader.result
			};
			setImgCurrent(file_body);
		}
		
		// console.log({
		// 	file: file,
		// 	imagePreviewUrl: reader.result
		// })
		// this.setState()
		}

		reader.readAsDataURL(file)
	}
	// useEffect(() => {
	// 	bridge.subscribe(({ detail: { type, data }}) => {
	// 		if (type === 'VKWebAppUpdateConfig') {
	// 			const schemeAttribute = document.createAttribute('scheme');
	// 			schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
	// 			document.body.attributes.setNamedItem(schemeAttribute);
	// 		}
	// 	});
	// 	async function fetchData() {
	// 		const user = await bridge.send('VKWebAppGetUserInfo');
	// 		setUser(user);
	// 		console.log(fetchedUser);
	// 		// setPopout(null);
	// 	}
	// 	fetchData();
	// }, []);

	// const get_user_id = () => {
	// 	const user = bridge.send('VKWebAppGetUserInfo');
	// 	setUserId(user.id);
	// 	console.log(user_id)
	// }
	// const setTextInputRef = element => {
	// 	textInput = element;
	//   };

	const swap_face = (id) => {
		console.log(Number(id));
	};
	// api.get(`api.php?m=getResultImage&user_id=${fetchedUser.id}&token=6FVXp9`)
	async function wait_result(){
		console.log(`${url_api}api.php?m=getResultImage&name_result_image=${last_image}&token=6FVXp9`)
		fetch(`${url_api}api.php?m=getResultImage&name_result_image=${last_image}&token=6FVXp9`)
		
		.then(res => res.json())
		.then(res => showResultImage(res))
		.catch(err => alert(err));
	}

	const showResultImage = (res) => {
		console.log(res);
		setResultImage(`http://swap-face.dnatree.ru/result_data/${res}`);
		setResultSpinner('');
	}

	async function test_fetch(){
		// const response = await axios.get("https://faceswap.dnatree.ru/api.php?m=getData&token=6FVXp9");
		// console.log(response.data[0]); <Icon16Done fill="#f"/>
		// console.log(img1.value);
		// console.log(img2.value);
		// console.log(id)
		if(file_face !== null & file_body !== null){
			last_image = parseInt(new Date().getTime()/1000) + `_${fetchedUser.id}.jpg`;
			console.log('last_image ' + last_image);
			// api.get(`api.php?m=deleteImage&token=6FVXp9&image_name=${fetchedUser.id}.jpg`)
			// fetch(`${url_api}api.php?m=getReadyOrders&token=6FVXp9`)
			// .then(res => res.json())
			// .then(res => console.log(res))`${url_api}api.php?m=deleteImage&token=6FVXp9&image_name=${last_image}`
			console.log(`https://swap-face.dnatree.ru/api.php?m=deleteImage&token=6FVXp9&image_name=${last_image}`);
			await fetch( `https://swap-face.dnatree.ru/api.php?m=deleteImage&token=6FVXp9&image_name=${last_image}`)
			.then(res => res.json())
			.then(res => console.log(res))
			.then(res => console.log("deleteImage succesfull!"))
			// await fetch(`${url_api}api.php?m=getReadyOrders&token=6FVXp9`)
			// .then(res => res.json())
			// .then(res => console.log(res))
			// .catch(err => alert(err));
			// await console.log(`${url_api}api.php?m=deleteImage&token=6FVXp9&image_name=${last_image}`);
			// await api.get(`api.php?m=deleteImage&token=6FVXp9&image_name=${fetchedUser.id}.jpg`)
			// .then(res => console.log("deleteImage succesfull!"));
			// .then(response => console.log(response));
			// fetch(`${url_api}api.php?m=deleteImage&token=6FVXp9&image_name=${last_image}`)
			// .then(response => console.log(response));
			// api.get(`api.php?m=deleteRow&token=6FVXp9&user_id=${fetchedUser.id}`)
			// .then(
			// 	res => api.get(`api.php?m=createRow&token=6FVXp9&user_id=${fetchedUser.id}&file_face=${fetchedUser.id}_face.jpg&file_body=${fetchedUser.id}_body.jpg`)
			// 	// .then(res => console.log(res))
			// );
			console.log(url_api + `api.php?m=createRow&token=6FVXp9&user_id=${fetchedUser.id}&file_face=${fetchedUser.id}_face.jpg&file_body=${fetchedUser.id}_body.jpg&name_result_image=${last_image}`);
			// api.get(`api.php?m=createRow&token=6FVXp9&user_id=${fetchedUser.id}&file_face=${fetchedUser.id}_face.jpg&file_body=${fetchedUser.id}_body.jpg`)
			await fetch(url_api + `api.php?m=createRow&token=6FVXp9&user_id=${fetchedUser.id}&file_face=${fetchedUser.id}_face.jpg&file_body=${fetchedUser.id}_body.jpg&name_result_image=${last_image}`)
			.then(res => res.json())
			// .then(res => console.log(res))
			.then(res => console.log("createRow succesfull!"))
			// .catch(err => alert(err));
			// // .then(response => console.log(response))
			// .then(
			// 	() => api.get(`api.php?m=createRow&token=6FVXp9&user_id=${fetchedUser.id}&file_face=${fetchedUser.id}_face.jpg&file_body=${fetchedUser.id}_body.jpg`)
			// .then(response => response.json())
			// .then(res => console.log(res[0])));
			// fetch(`${url_api}api.php?m=deleteRow&token=6FVXp9&user_id=${fetchedUser.id}`)
			// .then(response => console.log(response))
			// .then(() => fetch(`${url_api}api.php?m=createRow&token=6FVXp9&user_id=${fetchedUser.id}&file_face=${fetchedUser.id}_face.jpg&file_body=${fetchedUser.id}_body.jpg`)
			// .then(response => response.json())
			// .then(res => console.log(res[0])));
			await api.get(`api.php?m=deleteResultRow&token=6FVXp9&user_id=${fetchedUser.id}`)
			.then(res => console.log("deleteResultRow"))
			// .catch(err => alert(err));
			// .then(response => console.log(response))
			// fetch(`${url_api}api.php?m=deleteResultRow&token=6FVXp9&user_id=${fetchedUser.id}`)
			// .then(response => console.log(response));
			// setPopout(<ScreenSpinner size='large' />);
			setActivePanel('result');
			setResultImage('');
			setResultSpinner(<Spinner id='spinner' size="large" style={{ marginTop: 20 }} />);
			wait_result();
		}
		else{
			if (snackbar) return;
			setSnackbar(
				<Snackbar 
					layout="vertical"
					before={<Avatar size={24} style={{backgroundColor: '#f56767'}}><Icon16Cancel fill="#fff" width={14} height={14} /></Avatar>}
					onClose={() => setSnackbar(null)} >
						Загрузите обе картинки
				</Snackbar>
			);
		}
	}

	const go = e => {
		if(e.currentTarget.dataset.to == 'home'){
			fetch( `https://swap-face.dnatree.ru/api.php?m=deleteImage&token=6FVXp9&image_name=${last_image}`)
			.then(res => res.json())
			.then(res => console.log(res))
			.then(res => console.log("deleteImage succesfull!"))
			file_face = null;
			file_body = null;
			styleShowButton = {
				file_face_button: true,
				file_body_button: true
				}
			setImgCurrent({
				file: null,
				imagePreviewUrl: default_image
			})
		}
		setActivePanel(e.currentTarget.dataset.to);
	};

	return (
		<View activePanel={activePanel} popout={popout}>
			<Home 	id='home'
				 	fetchedUser={fetchedUser}
					swap_face={test_fetch} 
					snackbar={snackbar} 
					ImgCurrent={ImgCurrent} 
					uploadImage={uploadImage}
					styleShowButton={styleShowButton}
					changeShowImage={changeShowImage}
					snackbar={snackbar}
				/>
			<Result id='result' go={go} resultImage={resultImage} resultSpinner={resultSpinner}/>
		</View>
	);
}

export default App;

